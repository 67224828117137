<div id="sideBar" class="overflow-auto shadow-lg bg-color  sideBar journal-scroll">
	<nav class="flex flex-col text-center pt-1 ">
		<ul class="flex flex-col text-center">
			<a class="px-2 py-4 cursor-pointer sidebarMenuCustomStyle"
				[ngStyle]="Global.openPage == 'home' ? {'border-left':'5px solid '+Global.first_color, 'background':'#0028500d'}:{}"
				(click)="loadPage('home')">
				<div class="justify-center rounded-md cursor-pointer" [style.color]="Global.first_color">
					<div class="icon">
						<i class='bx bx-home text-6xl'></i>
					</div>
					<div class="text">
						<div class="fontMediumBold">DashBoard</div>
					</div>
				</div>
			</a>
			<a class="px-2 py-4 cursor-pointer sidebarMenuCustomStyle" routerLink="/driver-shift" target="_blank">
				<div class="justify-center rounded-md cursor-pointer" [style.color]="Global.first_color">
					<div class="icon">
						<i class='bx bxs-user-detail text-6xl'></i>
					</div>
					<div class="text">
						<div class="fontMediumBold">Driver Shift</div>
					</div>
				</div>
			</a>
			<a class="px-2 py-4 cursor-pointer sidebarMenuCustomStyle" routerLink="/task-timeline" target="_blank">
				<div class="justify-center rounded-md cursor-pointer" [style.color]="Global.first_color">
					<div class="icon">
						<i class='bx bx-notepad text-6xl'></i>
					</div>
					<div class="text">
						<div class="fontMediumBold">Task Management</div>
					</div>
				</div>
			</a>
			<a class="px-2 py-4 cursor-pointer sidebarMenuCustomStyle" (click)="userChat()" target="_blank">
				<div class="justify-center rounded-md cursor-pointer" [style.color]="Global.first_color">
					<div class="icon">
						<i class='far fa-comments text-6xl'></i>
					</div>
					<div class="text">
						<div class="fontMediumBold">User Chat</div>
					</div>
				</div>
			</a>
			<a class="px-2 py-4 cursor-pointer sidebarMenuCustomStyle" (click)="dispatcherLeaderboard()"
				target="_blank">
				<div class="justify-center rounded-md cursor-pointer" [style.color]="Global.first_color">
					<div class="icon">
						<i class='bx bxs-dashboard text-6xl'></i>
					</div>
					<div class="text">
						<div class="fontMediumBold">Leaderboard</div>
					</div>
				</div>
			</a>
		</ul>
	</nav>
</div>
<nav class="px-2 fontMediumBold md:px-2 sm:px-2" style="max-height: 50px;" [ngStyle]="Global.setGradientStyle()">
	<div class="flex w-auto px-2 float-left py-2">
		<div class="flex">
			<div class="self-center" (click)="sideBarMenu()">
				<button class="relative z-10 rounded-md p-2 focus:outline-none">
					<i class="fa fa-bars text-4xl text-white" [style.color]="Global.second_color"></i>
				</button>
			</div>
			<div class="self-center ml-1 customLogoStyle" (click)="loadPage('home')">
				<img [src]="Global.companyDetails.companyLogo" class="headerLogoStyle" />
			</div>
		</div>

	</div>
	<div class="flex float-right ml-auto justify-items-center self-center">
		<ul class="flex self-center mb-0" [style.color]="Global.second_color">
			<!-- <li class="nav-item py-3 self-center cursor-pointer" (click)="dispatcherLeaderboard()">
				<h4 class="px-4 sm:px-2 md:px-2 sm:text-sm md:text-sm fontMediumBold flex items-center">
					<i class='bx bxs-dashboard mr-2' aria-hidden="true"></i>
					Leaderboard
				</h4>
			</li> -->
			<!-- <li class="nav-item py-3 self-center cursor-pointer" (click)="userChat()">
				<h4 class="px-4 sm:px-2 md:px-2 sm:text-sm md:text-sm fontMediumBold flex items-center">
					<i class="far fa-comments mr-2" aria-hidden="true"></i>
					User Chat
				</h4>
			</li> -->
			<li class="nav-item py-3 custom-dropdown-menu self-center fontMediumBold cursor-pointer"
				*ngIf="Global.roleJson.booking_alert['view'] == 1 || Global.roleJson.driver_alert['view'] == 1">
				<div
					class="custom-dropdown-menu px-4 sm:px-2 md:px-2 sm:text-sm md:text-sm fontMediumBold flex items-center">
					<i class='bx bx-notification text-3xl mr-1' aria-hidden="true"></i>
					Others

					<span class="numberCircle animate-bounce ml-1 inline-flex text-sm" style="font-size: 10px;"
						*ngIf="(Global.roleJson.booking_alert['view'] == 1 && Global.unReadAlert > 0) || (Global.roleJson.driver_alert['view'] == 1 && Global.unReadDriverAlert)">
						<!-- {{Global.roleJson.driver_alert['view'] == 1 && Global.roleJson.booking_alert['view'] == 1 && Global.unReadDriverAlert + Global.unReadAlert > 99 ? '99+':Global.unReadDriverAlert + Global.unReadAlert}}
						{{Global.roleJson.booking_alert['view'] == 1 && Global.unReadAlert > 99 ? '99+':Global.roleJson.driver_alert['view'] == 1 && Global.unReadDriverAlert > 99 ? '99+':''}} -->
						<ng-container
							*ngIf="Global.roleJson.driver_alert['view'] == 1 && Global.roleJson.booking_alert['view'] == 1;else second">
							{{ Global.unReadDriverAlert + Global.unReadAlert + unReadDriverBreakAlert > 99 ?
							'99+':Global.unReadDriverAlert +
							Global.unReadAlert}}
						</ng-container>
						<ng-template #second>
							<ng-container *ngIf="Global.roleJson.driver_alert['view'] == 1;else third">
								{{ Global.unReadDriverAlert > 99 ? '99+':Global.unReadDriverAlert}}
							</ng-container>
						</ng-template>
						<ng-template #third>
							{{ Global.unReadAlert > 99 ? '99+':Global.unReadAlert}}
						</ng-template>

					</span>
					<ul className="nav flex-column shadow">
						<div>
							<li className="list-item w-full" *ngIf="Global.roleJson.booking_alert['view'] == 1"><a
									class="nav-link m-0 pl-3  fontMedium" (click)="alertModalShow()">
									<i class='bx bx-notification text-3xl mr-1' aria-hidden="true"></i>Booking Alerts
									<span class="numberCircle animate-bounce ml-1 inline-flex text-sm"
										style="font-size: 10px;" *ngIf="Global.unReadAlert > 0">
										{{Global.unReadAlert > 99 ? '99+':Global.unReadAlert }}
									</span>
								</a>
							</li>
							<li className="list-item w-full" *ngIf="Global.roleJson.driver_alert['view'] == 1"><a
									class="nav-link m-0 pl-3  fontMedium" (click)="driverAlert()">
									<i class='bx bx-notification text-3xl mr-1' aria-hidden="true"></i>Driver Alerts</a>
								<span class="numberCircle animate-bounce ml-1 inline-flex text-sm"
									style="font-size: 10px;" *ngIf="Global.unReadDriverAlert > 0">
									{{Global.unReadDriverAlert > 99 ? '99+':Global.unReadDriverAlert}}
								</span>
							</li>
							<li className="list-item w-full" *ngIf="Global.roleJson.driver_alert['view'] == 1"><a
									class="nav-link m-0 pl-3  fontMedium" (click)="driverBreakAlert()">
									<i class='bx bx-notification text-3xl mr-1' aria-hidden="true"></i>Driver Break
									Alerts</a>
								<span class="numberCircle animate-bounce ml-1 inline-flex text-sm"
									style="font-size: 10px;" *ngIf="unReadDriverBreakAlert > 0">
									{{unReadDriverBreakAlert > 99 ? '99+':unReadDriverBreakAlert}}
								</span>
							</li>
						</div>
					</ul>
				</div>
			</li>
			<li class="nav-item py-3 self-center cursor-pointer" (click)="stickyNotes()"
				*ngIf="Global.roleJson.sticky['view'] == 1">
				<h4 class="px-4 sm:px-2 md:px-2 sm:text-sm md:text-sm fontMediumBold flex items-center">
					<i class='bx bx-note text-3xl mr-1' aria-hidden="true"></i>
					Sticky
					<span *ngIf="stickyBadgeCount != undefined && stickyBadgeCount != 0"
						class="numberCircle animate-bounce ml-1 inline-flex text-sm" style="font-size: 10px;">
						{{stickyBadgeCount > 99 ? '99+':stickyBadgeCount}}
					</span>
					<span *ngIf="stickyBadgeCount != undefined && stickyBadgeCount == 0 && isStickyUpdated"
						class="updateCircle animate-bounce ml-1 inline-flex text-sm" style="font-size: 10px;">
					</span>
					<span
						*ngIf="stickyBadgeCount != undefined && stickyBadgeCount == 0 && isStickyDeleted && !isStickyUpdated"
						class="deleteCircle animate-bounce ml-1 inline-flex text-sm" style="font-size: 10px;">
					</span>
				</h4>
			</li>
			<li class="nav-item py-3 self-center cursor-pointer" (click)="chat()"
				*ngIf="Global.roleJson.chat['view'] == 1">
				<h4 class="px-4 sm:px-2 md:px-2 sm:text-sm md:text-sm fontMediumBold flex items-center">
					<i class="far fa-comments mr-2" aria-hidden="true"></i>
					Chat
					<span *ngIf="chatBadgeCount != undefined && chatBadgeCount != 0"
						class="numberCircle animate-bounce ml-1 inline-flex text-sm"
						style="font-size: 10px;">{{chatBadgeCount > 99 ? '99+':chatBadgeCount}}
					</span>
				</h4>
			</li>
			<!-- <li class="nav-item py-3 self-center fontMediumBold cursor-pointer" (click)="alertModalShow()">
				<h4 class="px-4 sm:px-2 md:px-2 sm:text-sm md:text-sm fontMediumBold flex items-center">
					<i class='bx bx-notification text-3xl mr-1' aria-hidden="true"></i>
					Alert <span *ngIf="Global.unReadAlert != 0"
						class="numberCircle animate-bounce ml-1 inline-flex text-sm"
						style="font-size: 10px;">{{Global.unReadAlert < 100 ? Global.unReadAlert : '99+' }}</span>
				</h4>
			</li> -->
			<li class="nav-item py-3 self-center fontMediumBold cursor-pointer" (click)="closedJob()"
				*ngIf="Global.roleJson.close_job['view'] == 1">
				<h4 class="px-4 sm:px-2 md:px-2 sm:text-sm md:text-sm fontMediumBold flex items-center">
					<i class='bx bx-door-open text-3xl mr-1' aria-hidden="true"></i>
					Close Job
				</h4>
			</li>
			<li class="nav-item py-3 self-center cursor-pointer" (click)="searchJob()"
				*ngIf="Global.roleJson.search_job['view'] == 1">
				<h4 class="px-4 sm:px-2 md:px-2 sm:text-sm md:text-sm fontMediumBold flex items-center">
					<i class='bx bx-search-alt text-3xl mr-1' aria-hidden="true"></i>
					Search Job
				</h4>
			</li>
			<li class="nav-item py-3 self-center fontMediumBold cursor-pointer" (click)="searchCustomer()"
				*ngIf="Global.roleJson.customer_details['view'] == 1">
				<h4 class="px-4 sm:px-2 md:px-2 sm:text-sm md:text-sm fontMediumBold flex items-center">
					<i class="fa fa-users mr-2" aria-hidden="true"></i>
					Customers
				</h4>
			</li>
			<li class="nav-item py-3 self-center">
				<h4 class="px-2 fontMediumBold" style="text-align: center; width:235px;">
					<i class="far fa-clock px-2 rounded-full  animate-spin"
						aria-hidden="true"></i>{{Global.companyCurrentTime}}
				</h4>
			</li>
			<li class="nav-item py-3 self-center">
				<div class="ml-3">
					<img [src]="Global.dispatcherDetails.dispatcherImage"
						class="w-12 h-12 rounded-full border overflow-hidden" />
				</div>
			</li>
			<li class="nav-item py-3 self-center">
				<h4 class="fontMediumBold px-4">{{Global.dispatcherDetails.dispatcherName}}</h4>
			</li>
			<li class="nav-item py-3 px-3 self-center cursor-pointer" #settingIconContains id="settingIconContains">
				<i class="bx bx-cog bx bx-spin white text-3xl"></i>
			</li>
			<li class="nav-item py-3 px-3 self-center">
				<div class="cursor-pointer">
					<i [hidden]="!logoutLoader" (click)="logout()" class="fa fa-power-off hover:text-red-900"></i>
					<img [hidden]="logoutLoader" src="assets/images/round-loder.gif"
						class="w-8 h-12 rounded-full logoutLoader" />
				</div>
			</li>
		</ul>
	</div>
	<div style="clear: both;"></div>
	<div id="themeBar" #sideBarMenuContains
		class="overflow-auto shadow-lg bg-color  customizer themeBar journal-scroll">
		<nav class="flex flex-col text-center">
			<div class="sticky top-0 bg-color z-40 pt-0 bottomBorder">
				<div class="mt-1">
					<span (click)="toggleSideBar(false)" class="customizer-close darkfontColor">
						<i
							class="fas fa-times-circle text-2xl cursor-pointer closeDriverAssignIconStyle text-red-500"></i>
					</span>
				</div>
				<div class="mt-4 mb-4 pb-2">
					<div class="fontMediumBold text-7xl darkfontColor" style="font-size: 20px !important;">
						Theme Customizer
					</div>
					<small class="darkfontColor fontMediumBold">Customize &amp; Preview in Real Time</small>
				</div>
			</div>
			<div class="customizer-content p-2">
				<!-- Dark Light Theme options starts -->
				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Theme Mode
					</h5>
					<hr class="m-2">
					<div class="flex mt-3 text-center justify-center">
						<div class="flex justify-between flex-row">
							<div class="flex flex-col">
								<label for="lightSwitch" class="btn-radio">
									<input type="radio" id="lightSwitch" class="loginThemeSwitch hidden"
										(click)="dashboardThemeChange(true)" name="themeSwitch"
										[checked]="Global.lightThemeButtonChecked" />
									<svg width="15px" class="mt-1" height="15px" viewBox="0 0 20 20">
										<circle cx="10" cy="10" r="9"></circle>
										<path
											d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
											class="inner"></path>
										<path
											d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
											class="outer"></path>
									</svg>
									<div class="darkfontColor custom-font-md ml-2">
										Light
									</div>
								</label>
							</div>
							<label for="darkSwitch" class="btn-radio">
								<input type="radio" id="darkSwitch" class="loginThemeSwitch"
									(click)="dashboardThemeChange(false)" name="themeSwitch"
									[checked]="!Global.lightThemeButtonChecked" />
								<svg width="15px" height="15px" class="mt-1" viewBox="0 0 20 20">
									<circle cx="10" cy="10" r="9"></circle>
									<path
										d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
										class="inner"></path>
									<path
										d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
										class="outer"></path>
								</svg>
								<p class="darkfontColor custom-font-md ml-2">Dark</p>
							</label>
						</div>
					</div>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Theme of Map
					</h5>
					<hr class="m-2">
					<div class="flex mt-3 text-center justify-center">
						<div class="flex justify-between flex-row">
							<div class="flex flex-col">
								<label for="lightMap" class="btn-radio">
									<input type="radio" id="lightMap" class="loginThemeSwitch hidden"
										(click)="dashboardMapThemeChange(true)" name="themeSwitchMap"
										[checked]="!Global.isMapDark" />
									<svg width="15px" class="mt-1" height="15px" viewBox="0 0 20 20">
										<circle cx="10" cy="10" r="9"></circle>
										<path
											d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
											class="inner"></path>
										<path
											d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
											class="outer"></path>
									</svg>
									<div class="darkfontColor custom-font-md ml-2">
										Light
									</div>
								</label>
							</div>
							<label for="darkMap" class="btn-radio">
								<input type="radio" id="darkMap" class="loginThemeSwitch"
									(click)="dashboardMapThemeChange(false)" name="themeSwitchMap"
									[checked]="Global.isMapDark" />
								<svg width="15px" height="15px" class="mt-1" viewBox="0 0 20 20">
									<circle cx="10" cy="10" r="9"></circle>
									<path
										d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
										class="inner"></path>
									<path
										d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
										class="outer"></path>
								</svg>
								<p class="darkfontColor custom-font-md ml-2">Dark</p>
							</label>
						</div>
					</div>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Display Mode
					</h5>
					<hr class="m-2">
					<div class="flex mt-3 text-center justify-center">
						<div class="flex justify-between flex-row">
							<div class="flex flex-col">
								<label for="addressSwitch" class="btn-radio">
									<input type="radio" id="addressSwitch" class="loginThemeSwitch hidden"
										(click)="changeShowAddresstype('address')" name="addressType"
										[checked]="Global.isShowAddressType == 'address'" />
									<svg width="15px" class="mt-1" height="15px" viewBox="0 0 20 20">
										<circle cx="10" cy="10" r="9"></circle>
										<path
											d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
											class="inner"></path>
										<path
											d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
											class="outer"></path>
									</svg>
									<div class="darkfontColor custom-font-md ml-2">
										Display Addresses
									</div>
								</label>
							</div>
							<label for="areaSwitch" class="btn-radio">
								<input type="radio" id="areaSwitch" class="loginThemeSwitch"
									(click)="changeShowAddresstype('area')" name="addressType"
									[checked]="Global.isShowAddressType == 'area'" />
								<svg width="15px" height="15px" class="mt-1" viewBox="0 0 20 20">
									<circle cx="10" cy="10" r="9"></circle>
									<path
										d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
										class="inner"></path>
									<path
										d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
										class="outer"></path>
								</svg>
								<p class="darkfontColor custom-font-md ml-2">Display Area's</p>
							</label>
						</div>
					</div>
					<p><span class="text-red-400">INFO : </span>You should check area's only if you have
						created zones
						and area's for that zones. Else it will load blank address on dispatcher panel.</p>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						{{ colors[0].title }}
					</h5>
					<hr class="m-2">
					<div class="grid grid-cols-6 mb-2">
						<div *ngFor="let color of colors[0].data" (click)="changeHeaderColor(color)">
							<div class="themeBox rounded-lg cursor-pointer" [style.background]="color" [id]="color">
							</div>
						</div>
					</div>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 mb-2 text-5xl darkfontColor col-span-6">
						Company And Custom Colors
					</h5>
					<hr class="m-2">
					<div class="flex mt-4 mb-2 items-center justify-center gap-10">
						<div (click)="changeHeaderColor(colors[1].data[0])" class="flex flex-col items-center">
							<div class="themeBox rounded-lg cursor-pointer mb-2" [style.background]="colors[1].data[0]"
								[id]="colors[1].data[0]"></div>
							<p class="fontMediumBold mt-1">{{ colors[1].title }}</p>
						</div>
						<div class="rounded-lg cursor-pointer flex flex-col items-center">
							<input type="color" name="color" (input)="CustomColorPicker($event)"
								class="themeBox rounded-lg cursor-pointer mb-2" [id]="colors[2].data[0]"
								[value]="colors[2].data[0]" />
							<p class="fontMediumBold mt-1"><i class="fas fa-paint-brush mr-1"></i>{{
								colors[2].title }}</p>
						</div>
					</div>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Manage Booking Listing's Colour Alerts
					</h5>
					<hr class="m-2">
					<div class="grid grid-cols-3 mt-2 mb-2 items-center">
						<div>
							<select name="assignTime" [(ngModel)]="Global.unassignBookingAlertTime"
								(change)="setUnassignBookingAlertTime($event)"
								class="focus:outline-none p-2 border boxBColor custom-font-md focus:ring-1 focus:ring-indigo-700 rounded-full scroller outline-none overflow-auto journal-scroll h-14">
								<option value="5" selected>5 {{ "min" }}</option>
								<option value="10">10 {{ "min" }}</option>
								<option value="15">15 {{ "min" }}</option>
								<option value="20">20 {{ "min" }}</option>
								<option value="30">30 {{ "min" }}</option>
								<option value="45">45 {{ "min" }}</option>
								<option value="60">1 {{ "hour" }}</option>
								<option value="120">2 {{ "hour" }}</option>
								<option value="180">3 {{ "hour" }}</option>
								<option value="240">4 {{ "hour" }}</option>
								<option value="300">5 {{ "hour" }}</option>
							</select>
						</div>
						<div>
							<select name="assignTimeOrPickupTime"
								[(ngModel)]="Global.calculateUnAssignBookingAlertTimeBasedOn"
								(change)="calculateUnAssignBookingAlertTimeBasedOn($event)"
								class="focus:outline-none p-2 border boxBColor custom-font-md focus:ring-1 focus:ring-indigo-700 rounded-full scroller outline-none overflow-auto journal-scroll h-14">
								<option value="assignTime" selected>Assign Time</option>
								<option value="pickupTime">Pickup Time</option>
							</select>
						</div>
						<div>
							<div class="rounded-lg cursor-pointer flex-col flex items-center">
								<input type="color" name="color" (input)="unassignbookingAlertTimeColorPicker($event)"
									class="themeBox rounded-lg cursor-pointer mb-2"
									[id]="Global.unassignBookingAlertColor"
									[value]="Global.unassignBookingAlertColor" />
								<p class="fontMediumBold mt-1"><i class="fas fa-paint-brush mr-1"></i>Set
									Color</p>
							</div>
						</div>
					</div>
					<hr class="m-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Dispatcher Audio Settings for Unassign Tab
					</h5>
					<hr class="m-2">
					<div class="py-2 pl-2">
						<label for="bookingAssignRemainder" class="flex items-center cursor-pointer">
							<div class="relative">
								<input type="checkbox" [checked]="Global.audioObj.unassignTab.bookingAssignRemainder"
									id="bookingAssignRemainder"
									(change)="unassignTabAudio($event, 'bookingAssignRemainder')" class="sr-only" />
								<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
								<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
								</div>
							</div>
							<div class="ml-3  fontMediumBold">
								Booking Assign Remainder On/Off
							</div>
						</label>
					</div>
					<div *ngIf="Global.audioObj.unassignTab.bookingAssignRemainder == true">
						<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
							Set Alert Off Threshold Time
						</h5>
						<div class="grid grid-cols-3 mt-2 mb-2 items-center">
							<div>
								<select name="assignTime" [(ngModel)]="Global.unassignBookingAlertOffTime"
									(change)="setUnassignBookingAlertOffTime($event)"
									class="focus:outline-none p-2 border boxBColor custom-font-md focus:ring-1 focus:ring-indigo-700 rounded-full scroller outline-none overflow-auto journal-scroll h-14">
									<option value="5" selected>5 {{ "min" }}</option>
									<option value="10">10 {{ "min" }}</option>
									<option value="30">30 {{ "min" }}</option>
									<option value="45">45 {{ "min" }}</option>
									<option value="60">1 {{ "hour" }}</option>
									<option value="120">2 {{ "hour" }}</option>
									<option value="240">4 {{ "hour" }}</option>
									<option value="360">12 {{ "hour" }}</option>
									<option value="720">12 {{ "hour" }}</option>
									<option value="1440">1 {{ "day" }}</option>
									<option value="2880">2 {{ "days" }}</option>
									<option value="4320">3 {{ "days" }}</option>
									<option value="5760">4 {{ "days" }}</option>
									<option value="7200">5 {{ "days" }}</option>
								</select>
							</div>
						</div>
						<p><span class="text-red-400">INFO : </span>Any bookings whose pick-up time has
							elapsed this
							parameter will not ring in dispatcher panel.</p>
					</div>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Manage Alert Popup
					</h5>
					<hr class="m-2">
					<div class="flex items-center mt-4 pl-2 w-full mb-2">
						<label for="showOfflineDriverAlert" class="flex items-center cursor-pointer">
							<div class="relative">
								<input type="checkbox" [checked]="Global.manageAlertPopup.offlineDriver == true"
									id="showOfflineDriverAlert" (change)="manageAlertPopup($event,'offlineDriver')"
									class="sr-only" />
								<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
								<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
								</div>
							</div>
							<div class="ml-3  fontMediumBold">
								Show offline driver alert.
							</div>
						</label>
					</div>
					<div class="flex items-center mt-4 pl-2 w-full mb-2">
						<label for="showOfflineDriverWarningAlert" class="flex items-center cursor-pointer">
							<div class="relative">
								<input type="checkbox" [checked]="Global.manageAlertPopup.offlineDriverWarning == true"
									id="showOfflineDriverWarningAlert"
									(change)="manageAlertPopup($event,'offlineDriverWarning')" class="sr-only" />
								<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
								<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
								</div>
							</div>
							<div class="ml-3  fontMediumBold">
								Show offline driver warning alert.
							</div>
						</label>
					</div>
				</div>




				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Visible Only Nearest Driver
					</h5>
					<hr class="m-2">
					<div class="flex items-center mt-4 pl-2 w-full mb-2">
						<label for="isSetDriverLimit" class="flex items-center cursor-pointer">
							<div class="relative" [hidden]="Global.disable == true">
								<input type="checkbox" [disabled]="Global.disable == true"
									[checked]="Global.isSetDriverLimit" id="isSetDriverLimit"
									(change)="setDriverLimit($event)" class="sr-only" />
								<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
								<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
								</div>
							</div>
							<img [hidden]="Global.disable != true" src="assets/images/round-loder.gif"
								class="w-10 h-10 rounded-full" />
							<div class="ml-3  fontMediumBold">
								Visible Only Nearest Driver
							</div>
						</label>
					</div>
					<p><span class="text-red-400">INFO : </span>If you have large fleet's we recommend you
						to keep this
						features ON.</p>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Manage Create Bookings Alert Popup
					</h5>
					<hr class="m-2">
					<div class="flex items-center mt-4 pl-2 w-full mb-2">
						<label for="show_popup_user_create_booking" class="flex items-center cursor-pointer">
							<div class="relative">
								<input type="checkbox"
									[checked]="Global.createBookingShowsPopup.show_popup_user_create_booking"
									id="show_popup_user_create_booking" (change)="setCreateBookingPopUp($event,'app')"
									class="sr-only" />
								<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
								<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
								</div>
							</div>
							<div class="ml-3  fontMediumBold">
								Hide/Show Un-Approve bookings details popup
							</div>
						</label>
					</div>
					<!-- <div class="flex items-center mt-4 pl-2 w-full mb-2">
						<label for="show_popup_dispatcher_create_booking" class="flex items-center cursor-pointer">
							<div class="relative" >
								<input type="checkbox"  
									[checked]="Global.createBookingShowsPopup.show_popup_dispatcher_create_booking" id="show_popup_dispatcher_create_booking"
									(change)="setCreateBookingPopUp($event,'dispatcher')" class="sr-only" />
								<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
								<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
							</div>
							<div class="ml-3  fontMediumBold">
								Create booking alerts from dispatcher
							</div>
						</label>
					</div>	  -->
					<p><span class="text-red-400">INFO : </span>If this switch is off, then you can't see
						Un-Approve
						bookings details popup.</p>
				</div>


				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Dispatcher Audio Settings for New Create Booking's Alert Popup

					</h5>
					<hr class="m-2">
					<div class="py-2 pl-2">
						<label for="createAcceptBooking" class="flex items-center cursor-pointer">
							<div class="relative">
								<input type="checkbox"
									[checked]="Global.audioObj.createBookingNotifyAlert.dispatcherAccept"
									id="createAcceptBooking"
									(change)="manageAlertCreatedBookingSound($event, 'createAcceptBooking')"
									class="sr-only" />
								<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
								<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
								</div>
							</div>
							<div class="ml-3  fontMediumBold">
								Should beep on create booking accept by dispatcher.
							</div>
						</label>
					</div>
					<div class="py-2 pl-2">
						<label for="createDeclineBooking" class="flex items-center cursor-pointer">
							<div class="relative">
								<input type="checkbox"
									[checked]="Global.audioObj.createBookingNotifyAlert.dispatcherDecline"
									id="createDeclineBooking"
									(change)="manageAlertCreatedBookingSound($event, 'createDeclineBooking')"
									class="sr-only" />
								<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
								<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
								</div>
							</div>
							<div class="ml-3  fontMediumBold">
								Should beep on create booking decline by dispatcher.
							</div>
						</label>
					</div>
				</div>


				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2" *ngIf="Global.isSetDriverLimit == true">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Find Driver Setting
					</h5>
					<hr class="m-2">
					<div class="flex mt-3 text-center justify-center">
						<div class="flex justify-between flex-row">
							<div class="flex flex-col">
								<label for="findDriverVarian" class="btn-radio">
									<input type="radio" id="findDriverVarian" class="loginThemeSwitch hidden"
										(click)="changeFindDriverType('variant')" name="findDriverType"
										[checked]="Global.findDriverSettings.type == 'variant'" />
									<svg width="15px" class="mt-1" height="15px" viewBox="0 0 20 20">
										<circle cx="10" cy="10" r="9"></circle>
										<path
											d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
											class="inner"></path>
										<path
											d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
											class="outer"></path>
									</svg>
									<div class="darkfontColor custom-font-md ml-2">
										Variant
									</div>
								</label>
							</div>
							<div class="flex flex-col ml-2">
								<label for="findDriverCount" class="btn-radio">
									<input type="radio" id="findDriverCount" class="loginThemeSwitch"
										(click)="changeFindDriverType('count')" name="findDriverType"
										[checked]="Global.findDriverSettings.type == 'count'" />
									<svg width="15px" height="15px" class="mt-1" viewBox="0 0 20 20">
										<circle cx="10" cy="10" r="9"></circle>
										<path
											d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
											class="inner"></path>
										<path
											d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
											class="outer"></path>
									</svg>
									<p class="darkfontColor custom-font-md ml-2">Driver Count</p>
								</label>
							</div>
						</div>
					</div>
					<div class="grid grid-cols-2 mt-4 mb-2">
						<div>
							<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
								<b>Variant</b>
							</h5>
							<select name="findDriverVariant" [(ngModel)]="Global.findDriverSettings.variant"
								(change)="changeFindDriverVariant($event)"
								class="focus:outline-none p-2 border boxBColor custom-font-md focus:ring-1 focus:ring-indigo-700 rounded-full scroller outline-none overflow-auto journal-scroll h-14">
								<option value="5" selected>5 {{Global.distanceVariant}}</option>
								<option value="10">10 {{Global.distanceVariant}}</option>
								<option value="15">15 {{Global.distanceVariant}}</option>
								<option value="20">20 {{Global.distanceVariant}}</option>
								<option value="25">25 {{Global.distanceVariant}}</option>
								<option value="30">30 {{Global.distanceVariant}}</option>
								<option value="35">35 {{Global.distanceVariant}}</option>
								<option value="40">40 {{Global.distanceVariant}}</option>
								<option value="45">45 {{Global.distanceVariant}}</option>
								<option value="50">50 {{Global.distanceVariant}}</option>
							</select>
						</div>
						<div *ngIf="Global.findDriverSettings.type != 'variant'">
							<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
								<b>Driver Count</b>
							</h5>
							<select name="findDriverCount" [(ngModel)]="Global.findDriverSettings.count"
								(change)="changeFindDriverCount($event)"
								class="focus:outline-none p-2 border boxBColor custom-font-md focus:ring-1 focus:ring-indigo-700 rounded-full scroller outline-none overflow-auto journal-scroll h-14">
								<option value="5" selected>5</option>
								<option value="10">10</option>
								<option value="15">15</option>
								<option value="20">20</option>
								<option value="30">30</option>
								<option value="40">40</option>
								<option value="50">50</option>
								<option value="60">60</option>
								<option value="70">70</option>
								<option value="80">80</option>
								<option value="90">90</option>
								<option value="100">100</option>
								<option value="150">150</option>
								<option value="200">200</option>
							</select>
						</div>
					</div>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Visible Shimmer Effect
					</h5>
					<hr class="m-2">
					<div class="flex items-center mt-4 pl-2 w-full mb-2">
						<label for="toggleB" class="flex items-center cursor-pointer">
							<div class="relative">
								<input type="checkbox" [checked]="Global.OnEventShowShimmer" id="toggleB"
									(change)="showShimmerOnReload($event)" class="sr-only" />
								<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
								<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
								</div>
							</div>
							<div class="ml-3  fontMediumBold">
								Visible Shimmer Effect.
							</div>
						</label>
					</div>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Copy Account ID
					</h5>
					<hr class="m-2">
					<div class="flex items-center pl-2 mt-4 w-full mb-2">
						<label for="isCopyAccountId" class="flex items-center cursor-pointer">
							<div class="relative">
								<input type="checkbox" [checked]="Global.isCopyAccountId" id="isCopyAccountId"
									(change)="isCopyAccountId($event)" class="sr-only" />
								<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
								<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
								</div>
							</div>
							<div class="ml-3  fontMediumBold">
								Copy Account ID
							</div>
						</label>
					</div>
					<p><span class="text-red-400">INFO : </span>This will copy account id while pressing
						copy button
						from any of the jobs listed.</p>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Customizing Driver Labels on Map
					</h5>
					<hr class="m-2">
					<div class="grid grid-cols-2 mt-4 mb-2 pl-10 items-center">
						<div>
							<label for="driverMarkerLable" class="flex items-center cursor-pointer">
								<div class="relative">
									<input type="checkbox" [checked]="Global.driverMarkerLabel.isShow"
										id="driverMarkerLable" (change)="showMarkerLable($event)" class="sr-only" />
									<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
									<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
									</div>
								</div>
								<div class="ml-3  fontMediumBold">Label On/Off</div>
							</label>
						</div>
						<div>
							<div class="rounded-lg cursor-pointer">
								<input type="color" name="colorDriverMarker" (change)="driverMarkerLabelColor($event)"
									class="themeBox1 rounded-lg cursor-pointer mb-2"
									[value]="Global.driverMarkerLabel.color" [id]="Global.driverMarkerLabel.color" />
								<b class="fontMediumBold mt-1"><i class="fas fa-paint-brush mr-1"></i>label
									Color</b>
							</div>
						</div>
					</div>
					<hr />
					<div class="grid grid-cols-2 mt-4 mb-2">
						<div>
							<h4 class="fontMediumBold mt-4 text-5xl darkfontColor">
								<b>Label Position</b>
							</h4>
							<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
								<b>X Y</b>
							</h5>
							<select name="XDirection" (change)="driverMarkerLabelPosition($event, 'x')"
								[(ngModel)]="Global.driverMarkerIcon.labelOrigin.x"
								class="focus:outline-none border custom-font-md boxBColor rounded-l-full p-2 scroller focus:ring-1 focus:ring-indigo-700 outline-none overflow-auto journal-scroll h-14">
								<option value="-40">-40</option>
								<option value="-30">-30</option>
								<option value="-25">-25</option>
								<option value="-20">-20</option>
								<option value="-15">-15</option>
								<option value="-10">-10</option>
								<option value="-5">-5</option>
								<option value="0" selected>0</option>
								<option value="5">5</option>
								<option value="10">10</option>
								<option value="15">15</option>
								<option value="20">20</option>
								<option value="25">25</option>
								<option value="30">30</option>
								<option value="40">40</option>
							</select>
							<select name="YDirection" (change)="driverMarkerLabelPosition($event, 'y')"
								[(ngModel)]="Global.driverMarkerIcon.labelOrigin.y"
								class="focus:outline-none border boxBColor custom-font-md rounded-r-full p-2 scroller focus:ring-1 focus:ring-indigo-700 outline-none overflow-auto journal-scroll h-14">
								<option value="-40">-40</option>
								<option value="-30">-30</option>
								<option value="-25">-25</option>
								<option value="-20">-20</option>
								<option value="-15">-15</option>
								<option value="-10">-10</option>
								<option value="-5">-5</option>
								<option value="0" selected>0</option>
								<option value="5">5</option>
								<option value="10">10</option>
								<option value="15">15</option>
								<option value="20">20</option>
								<option value="25">25</option>
								<option value="30">30</option>
								<option value="40">40</option>
							</select>
						</div>
						<div>
							<h4 class="fontMediumBold mt-4 text-5xl darkfontColor">
								<b>Vehicle Icon Size </b>
							</h4>
							<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
								<b>Height width</b>
							</h5>
							<select name="vehicleHeight" [(ngModel)]="Global.driverMarkerIcon.height"
								(change)="setDriverMarkerIconHeightWidth($event, 'height')"
								class="focus:outline-none border custom-font-md boxBColor rounded-l-full p-2 scroller focus:ring-1 focus:ring-indigo-700 outline-none overflow-auto journal-scroll h-14">
								<option value="10">10</option>
								<option value="15">15</option>
								<option value="20">20</option>
								<option value="25">25</option>
								<option value="30">30</option>
								<option value="35">35</option>
								<option value="40" selected>40</option>
								<option value="45">45</option>
								<option value="50">50</option>
								<option value="55">55</option>
								<option value="60">60</option>
								<option value="70">70</option>
							</select>
							<select name="vehicleWidth" [(ngModel)]="Global.driverMarkerIcon.width"
								(change)="setDriverMarkerIconHeightWidth($event, 'width')"
								class="focus:outline-none border boxBColor custom-font-md rounded-r-full p-2 scroller focus:ring-1 focus:ring-indigo-700 outline-none overflow-auto journal-scroll h-14">
								<option value="10">10</option>
								<option value="15">15</option>
								<option value="20">20</option>
								<option value="25">25</option>
								<option value="30" selected>30</option>
								<option value="35">35</option>
								<option value="40">40</option>
								<option value="45">45</option>
								<option value="50">50</option>
								<option value="55">55</option>
								<option value="60">60</option>
								<option value="70">70</option>
							</select>
						</div>
					</div>
					<hr />
					<div class="grid grid-cols-2 mt-4 mb-2">
						<div>
							<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
								<b> Label Font Size</b>
							</h5>
							<select name="LabelFontSize" [(ngModel)]="Global.driverMarkerLabel.fontSize"
								(change)="driverMarkerLabelFontSize($event)"
								class="focus:outline-none p-2 border boxBColor custom-font-md focus:ring-1 focus:ring-indigo-700 rounded-full scroller outline-none overflow-auto journal-scroll h-14">
								<option value="2px" selected>2px</option>
								<option value="5px">5px</option>
								<option value="7px">7px</option>
								<option value="10px">10px</option>
								<option value="12px">12px</option>
								<option value="14px">14px</option>
								<option value="18px">18px</option>
								<option value="22px">22px</option>
								<option value="26px">26px</option>
								<option value="30px">30px</option>
								<option value="35px">35px</option>
							</select>
						</div>
						<div>
							<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
								<b> Label Font Weight</b>
							</h5>
							<select name="LabelFontWeight" [(ngModel)]="Global.driverMarkerLabel.fontWeight"
								(change)="driverMarkerLabelFontWeight($event)"
								class="focus:outline-none p-2 border boxBColor custom-font-md focus:ring-1 focus:ring-indigo-700 rounded-full scroller outline-none overflow-auto journal-scroll h-14">
								<option value="bold" selected>bold</option>
								<option value="bolder">bolder</option>
								<option value="100">100</option>
								<option value="200">200</option>
								<option value="300">300</option>
								<option value="400">400</option>
								<option value="500">500</option>
								<option value="600">600</option>
								<option value="700">700</option>
								<option value="800">800</option>
								<option value="900">900</option>
							</select>
						</div>
					</div>
					<!-- <div class="note-parent">
						<div class="note-div1">Note:</div>
						<div class="note-div2 text-left flex "><span
								class="break-all ">visghasdsfadhgashgdfasdgfgashdfgasfdgsafdgfasdggasddffsdgfghsadfsdgf</span>
						</div>
					</div> -->

				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Dispatcher Audio Settings for Booking's Alert
					</h5>
					<hr class="m-2">
					<div class="pl-2">
						<div>
							<label for="sosAlert" class="flex cursor-pointer">
								<div class="relative">
									<input type="checkbox" [checked]="Global.audioObj.bookingAlert.sos" id="sosAlert"
										(change)="BookingAlertAudio($event, 'sos')" class="sr-only" />
									<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
									<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
									</div>
								</div>
								<div class="ml-3  fontMediumBold">Should Beep on SOS</div>
							</label>
						</div>
						<hr class="m-2">
						<div>
							<label for="rejectBookingAlert" class="flex cursor-pointer">
								<div class="relative">
									<input type="checkbox" [checked]="Global.audioObj.bookingAlert.rejectBooking"
										id="rejectBookingAlert" (change)="BookingAlertAudio($event, 'rejectBooking')"
										class="sr-only" />
									<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
									<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
									</div>
								</div>
								<div class="ml-3  fontMediumBold">
									Should Beep on Rejection of Manual Booking's
								</div>
							</label>
						</div>
						<hr class="m-2">
						<div>
							<label for="rejectBookingAutomaticAlert" class="flex cursor-pointer">
								<div class="relative">
									<input type="checkbox"
										[checked]="Global.audioObj.bookingAlert.rejectBookingAutomatic"
										id="rejectBookingAutomaticAlert"
										(change)="BookingAlertAudio($event, 'rejectBookingAutomatic')"
										class="sr-only" />
									<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
									<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
									</div>
								</div>
								<div class="ml-3  fontMediumBold">
									Should Beep on Rejection of Automatic Booking's
								</div>
							</label>
						</div>
						<hr class="m-2">
						<div>
							<label for="driverCancelBookingAlert" class="flex cursor-pointer">
								<div class="relative">
									<input type="checkbox" [checked]="Global.audioObj.bookingAlert.driverCancelBooking"
										id="driverCancelBookingAlert"
										(change)="BookingAlertAudio($event, 'driverCancelBooking')" class="sr-only" />
									<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
									<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
									</div>
								</div>
								<div class="ml-3  fontMediumBold">
									Should Beep when Driver Cancel any Booking's
								</div>
							</label>
						</div>
						<hr class="m-2">
						<div class="py-2 pl-2">
							<label for="createDeclineBookingNotify" class="flex items-center cursor-pointer">
								<div class="relative">
									<input type="checkbox"
										[checked]="Global.audioObj.createBookingPopupAlert.createBookingPopupNotify"
										id="createDeclineBookingNotify"
										(change)="manageAlertCreatedBookingSound($event, 'createBookingNotify')"
										class="sr-only" />
									<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
									<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
									</div>
								</div>
								<div class="ml-3  fontMediumBold">
									Should beep on create booking.
								</div>
							</label>
						</div>
					</div>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Dispatcher Audio Settings for Drivers Alert
					</h5>
					<hr class="m-2">
					<div class="pl-2">
						<div>
							<label for="offlineWarning" class="flex items-center cursor-pointer">
								<div class="relative">
									<input type="checkbox" [checked]="Global.audioObj.driverAlert.offlineWarning"
										id="offlineWarning" (change)="DriverAlertAudio($event, 'offlineWarning')"
										class="sr-only" />
									<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
									<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
									</div>
								</div>
								<div class="fontMediumBold">
									Should Beep for Driver Issued Offline Warning's
								</div>
							</label>
						</div>
						<hr class="m-2">
						<div>
							<label for="onlineAlert" class="flex items-center cursor-pointer">
								<div class="relative">
									<input type="checkbox" [checked]="Global.audioObj.driverAlert.online"
										id="onlineAlert" (change)="DriverAlertAudio($event, 'online')"
										class="sr-only" />
									<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
									<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
									</div>
								</div>
								<div class="fontMediumBold">Should Beep when Driver went Online</div>
							</label>
						</div>
						<hr class="m-2">
						<div>
							<label for="offlineAlert" class="flex items-center cursor-pointer">
								<div class="relative">
									<input type="checkbox" [checked]="Global.audioObj.driverAlert.offline"
										id="offlineAlert" (change)="DriverAlertAudio($event, 'offline')"
										class="sr-only" />
									<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
									<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
									</div>
								</div>
								<div class="fontMediumBold">Should Beep when Driver went Offline</div>
							</label>
						</div>
					</div>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Dispatcher Audio Settings for Sticky Alert
					</h5>
					<hr class="m-2">
					<div class="py-2 pl-2">
						<label for="stickyAdd" class="flex items-center cursor-pointer">
							<div class="relative">
								<input type="checkbox" [checked]="Global.audioObj.sticky.add" id="stickyAdd"
									(change)="stickyAudio($event, 'add')" class="sr-only" />
								<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
								<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
								</div>
							</div>
							<div class="ml-3  fontMediumBold">Sticky Add On/Off</div>
						</label>
					</div>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Dispatcher Audio Settings for Chat Alert
					</h5>
					<hr class="m-2">
					<div class="py-2 pl-2">
						<label for="chatAudio" class="flex items-center cursor-pointer">
							<div class="relative">
								<input type="checkbox" [checked]="Global.audioObj.chat.messageReceive" id="chatAudio"
									(change)="chatAudio($event, 'message')" class="sr-only" />
								<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
								<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
								</div>
							</div>
							<div class="ml-3  fontMediumBold">
								Message Receive On/Off
							</div>
						</label>
					</div>

					<hr class="m-2">
					<div class="py-2 pl-2">
						<label for="userChatAudio" class="flex items-center cursor-pointer">
							<div class="relative">
								<input type="checkbox" [checked]="Global.disableUserChatNotification" id="userChatAudio"
									(change)="userChatAudio($event, 'userChatAudio')" class="sr-only" />
								<div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
								<div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition">
								</div>
							</div>
							<div class="ml-3  fontMediumBold">
								User-Chat Notification On/Off
							</div>
						</label>
					</div>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Sorting Options For Driver's Listing
					</h5>
					<hr class="m-2">
					<div class="grid grid-cols-2 mt-4 mb-2">
						<div class="ml-3  fontMediumBold">
							Free Driver's
						</div>
						<div>
							<select name="assignTimeOrPickupTime"
								[(ngModel)]="Global.onlineDriverListSortBy.freeDriverList"
								(change)="sortOnlineDriverListArray($event, 'freeDriver')"
								class="focus:outline-none p-2 border boxBColor custom-font-md focus:ring-1 focus:ring-indigo-700 rounded-full scroller outline-none overflow-auto journal-scroll h-14">
								<option value="asc" selected>Ascending</option>
								<option value="desc">Descending</option>
							</select>
						</div>
					</div>
					<hr class="m-2">
					<div class="grid grid-cols-2 mt-4 mb-2">
						<div class="ml-3  fontMediumBold">
							Driver Who has Accepted Booking's
						</div>
						<div>
							<select name="assignTimeOrPickupTime" [(ngModel)]="
					                Global.onlineDriverListSortBy.acceptedBookingDriverList
					              " (change)="sortOnlineDriverListArray($event, 'acceptedDriver')"
								class="focus:outline-none p-2 border boxBColor custom-font-md focus:ring-1 focus:ring-indigo-700 rounded-full scroller outline-none overflow-auto journal-scroll h-14">
								<option value="asc">Ascending</option>
								<option value="desc">Descending</option>
							</select>
						</div>
					</div>
					<hr class="m-2">
					<div class="grid grid-cols-2 mt-4 mb-2">
						<div class="ml-3  fontMediumBold">
							Driver's Who Arrived
						</div>
						<div>
							<select name="assignTimeOrPickupTime" [(ngModel)]="
					                Global.onlineDriverListSortBy.ontripBookingDriverList
					              " (change)="sortOnlineDriverListArray($event, 'onTripDriver')"
								class="focus:outline-none p-2 border boxBColor custom-font-md focus:ring-1 focus:ring-indigo-700 rounded-full scroller outline-none overflow-auto journal-scroll h-14">
								<option value="asc">Ascending</option>
								<option value="desc">Descending</option>
							</select>
						</div>
					</div>
					<hr class="m-2">
					<div class="grid grid-cols-2 mt-2 mb-2">
						<div class="ml-3  fontMediumBold">
							Drivers Who are On-Trip
						</div>
						<div>
							<select name="assignTimeOrPickupTime" [(ngModel)]="
					                Global.onlineDriverListSortBy.completedBookingDriverList
					              " (change)="sortOnlineDriverListArray($event, 'completedDriver')"
								class="focus:outline-none p-2 border boxBColor custom-font-md focus:ring-1 focus:ring-indigo-700 rounded-full scroller outline-none overflow-auto journal-scroll h-14">
								<option value="asc">Ascending</option>
								<option value="desc">Descending</option>
							</select>
						</div>
					</div>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Select Tarrif
					</h5>
					<hr class="m-2">
					<div class="flex items-center mt-2 justify-center w-full mb-2">
						<select name="assignTimeOrPickupTime" [(ngModel)]="Global.tarrif"
							(change)="tarrifSelect($event)"
							class="focus:outline-none p-2 border boxBColor custom-font-md focus:ring-1 focus:ring-indigo-700 rounded-full scroller outline-none overflow-auto journal-scroll h-14">
							<option value="automatic">Automatic</option>
							<option value="custom">Custom</option>
						</select>
					</div>
					<p><span class="text-red-400">INFO : </span>This value is auto-populated in dispatcher
						panel.
						Automatic tarrifs will add extra milage price + wait time to bookign's how ever
						custom makes fix
						price what's set in dispatcher panel ir-respective of wait and extra mile price.</p>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Default Assign time selection value
					</h5>
					<hr class="m-2">
					<div class="flex items-center mt-2 justify-center w-full mb-2">
						<select name="assignMin" (change)="setMinimumAssignMin($event)"
							[(ngModel)]="Global.minimumAssignMinutes"
							class=" w-52 p-2  custom-select boxBColor custom-font-md  rounded-full scroller custom-select overflow-auto journal-scroll h-12">
							<option value="5" selected> 5 {{ "min" }} </option>
							<option value="10"> 10 {{ "min" }} </option>
							<option value="15"> 15 {{ "min" }} </option>
							<option value="20"> 20 {{ "min" }} </option>
							<option value="30"> 30 {{ "min" }} </option>
							<option value="45"> 45 {{ "min" }} </option>
							<option value="60"> 1 {{ "h" }} 00 {{ "min" }}
							</option>
							<option value="75"> 1 {{ "h" }} 15 {{ "min" }}
							</option>
							<option value="90"> 1 {{ "h" }} 30 {{ "min" }}
							</option>
							<option value="120"> 2 {{ "h" }} 00 {{ "min" }}
							</option>
							<option value="150"> 2 {{ "h" }} 30 {{ "min" }}
							</option>
							<option value="180"> 3 {{ "h" }} 00 {{ "min" }}
							</option>
							<option value="210"> 3 {{ "h" }} 30 {{ "min" }}
							</option>
							<option value="240"> 4 {{ "h" }} 00 {{ "min" }}
							</option>
							<option value="270"> 4 {{ "h" }} 30 {{ "min" }}
							</option>
							<option value="300"> 5 {{ "h" }} 00 {{ "min" }}
							</option>
						</select>
					</div>
					<p><span class="text-red-400">INFO : </span>For faster booking experience we keep this
						selected
						value in assign time dropdown list.</p>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Delay Time Options
					</h5>
					<hr class="m-2">
					<div class="grid grid-cols-2 mt-4 mb-2">
						<div>
							<h5 class="mb-2">
								<b>Pickup Delay Time</b>
							</h5>
							<select name="pickupDelayTime" [(ngModel)]="Global.pickupDelay.time"
								(change)="pickupDelayTime($event)"
								class="focus:outline-none p-2 border boxBColor custom-font-md focus:ring-1 focus:ring-indigo-700 rounded-full scroller outline-none overflow-auto journal-scroll h-14">
								<option value="1" selected>1 min</option>
								<option value="2">2 min</option>
								<option value="3">3 min</option>
								<option value="5">5 min</option>
								<option value="7">7 min</option>
								<option value="9">9 min</option>
								<option value="10">10 min</option>
								<option value="15">15 min</option>
								<option value="20">20 min</option>
								<option value="25">25 min</option>
								<option value="30">30 min</option>
							</select>
						</div>
						<div>
							<h5 class="mb-2">
								<b>pickup Delay Color</b>
							</h5>
							<div class="themeBox1 rounded-lg cursor-pointer">
								<input type="color" name="pickupDelayColor" (input)="pickupDelayColorPicker($event)"
									class="themeBox1 rounded-lg cursor-pointer mb-2" id="pickupDelayColor"
									[value]="Global.pickupDelay.color" />
							</div>
						</div>
					</div>
					<div class="grid grid-cols-2 mt-4 mb-2">
						<div>
							<h5 class="mb-2">
								<b>Drop Delay Time</b>
							</h5>
							<select name="dropDelayTime" [(ngModel)]="Global.dropDelay.time"
								(change)="dropDelayTime($event)"
								class="focus:outline-none p-2 border boxBColor custom-font-md focus:ring-1 focus:ring-indigo-700 rounded-full scroller outline-none overflow-auto journal-scroll h-14">
								<option value="1" selected>1 min</option>
								<option value="2">2 min</option>
								<option value="3">3 min</option>
								<option value="5">5 min</option>
								<option value="7">7 min</option>
								<option value="9">9 min</option>
								<option value="10">10 min</option>
								<option value="15">15 min</option>
								<option value="20">20 min</option>
								<option value="25">25 min</option>
								<option value="30">30 min</option>
							</select>
						</div>
						<div>
							<h5 class="mb-2">
								<b>Drop Delay Color</b>
							</h5>
							<div class="themeBox1 rounded-lg cursor-pointer">
								<input type="color" name="dropDelayColor" (input)="dropDelayColorPicker($event)"
									class="themeBox1 rounded-lg cursor-pointer mb-2" id="dropDelayColor"
									[value]="Global.dropDelay.color" />
							</div>
						</div>
					</div>
					<div class="grid grid-cols-2 mt-4 mb-2">
						<div>
							<h5 class="mb-2">
								<b>Payment Delay Time</b>
							</h5>
							<select name="paymentDelayTimeTime" [(ngModel)]="Global.paymentDelayTime.time"
								(change)="paymentDelayTime($event)"
								class="focus:outline-none p-2 border boxBColor custom-font-md focus:ring-1 focus:ring-indigo-700 rounded-full scroller outline-none overflow-auto journal-scroll h-14">
								<option value="1" selected>1 min</option>
								<option value="2">2 min</option>
								<option value="3">3 min</option>
								<option value="5">5 min</option>
								<option value="7">7 min</option>
								<option value="9">9 min</option>
								<option value="10">10 min</option>
								<option value="15">15 min</option>
								<option value="20">20 min</option>
								<option value="25">25 min</option>
								<option value="30">30 min</option>
							</select>
						</div>
						<div>
							<h5 class="mb-2">
								<b>Payment Delay Color</b>
							</h5>
							<div class="themeBox1 rounded-lg cursor-pointer">
								<input type="color" name="paymentDelayTimeColor"
									(input)="paymentDelayTimeColorPicker($event)"
									class="themeBox1 rounded-lg cursor-pointer mb-2" id="paymentDelayTimeColor"
									[value]="Global.paymentDelayTime.color" />
							</div>
						</div>
					</div>
				</div>

				<div class="boxBColor rounded-2xl p-2 shadow-md mt-2">
					<h5 class="fontMediumBold mt-4 text-5xl darkfontColor">
						Wait Time Colors options
					</h5>
					<hr class="m-2">
					<div>
						<h5 class="mb-2"><b>Wait Time Color</b></h5>
						<div class="themeBox1 rounded-lg cursor-pointer">
							<input type="color" name="waitTimeColors" (input)="waitTimeColorPicker($event, false)"
								class="themeBox1 rounded-lg cursor-pointer mb-2" id="waitTimeColor"
								[value]="Global.waitTimeColor" />
						</div>
						<p><span class="text-red-400">INFO : </span>When the driver is actually waiting for
							Rider & not
							elapsed the wait time limit.</p>
					</div>
					<hr class="mb-2">
					<div>
						<h5 class="mb-2"><b>Delay Wait Time Color</b></h5>
						<div class="themeBox1 rounded-lg cursor-pointer">
							<input type="color" name="pickupDelayColor" (input)="waitTimeColorPicker($event, true)"
								class="themeBox1 rounded-lg cursor-pointer mb-2" id="delayWaitTimeColor"
								[value]="Global.waitTimeDelayColor" />
						</div>
						<p><span class="text-red-400">INFO : </span>When the driver is actually waiting for
							long and
							have crossed wait time limit.</p>
					</div>
				</div>
			</div>
		</nav>
	</div>
</nav>
<div class="w-full text-center internet fontMediumBold" [ngClass]="{
    onInternetAnimation: Global.noInternetConnection == 'onInternet',
    noInternetAnimation: Global.noInternetConnection == 'noInternet',
    internet: Global.noInternetConnection == 'internet'
  }">
	{{ Global.noInternetConnection == "noInternet" ? "No internet connection!!" : "Internet Connected" }}
</div>

<!-- Modal Alert Start -->
<app-modal #alertModal class="alertModal" [maximizable]="true" [backdrop]="false" (closeModal)="closeAlertModel()">
	<ng-container class="app-modal-header">
		<h4 class="text-3xl mr-1 self-center text-white fontMedium flex align-center items-center"><i
				class="bx bx-notification text-3xl mr-1" aria-hidden="true"></i> Alerts</h4>
	</ng-container>
	<ng-container class="app-modal-body">
		<div class="customerSerchBar boxBColor sticky top-0">
			<div class="flex p-4">
				<span class="flex items-center mx-2 fontMediumBold"><span class="reject-by-driver"
						style="font-size: 20px; background: none;">&#9673;&nbsp;</span>
					<span>Rejected By Driver</span>
				</span>
				<span class="flex items-center mx-2 fontMediumBold"><span class="reject-by-system"
						style="font-size: 20px; background: none;">&#9673;&nbsp;</span>
					<span>Rejected By System</span>
				</span>
				<span class="flex items-center mx-2 fontMediumBold"><span class="sos-alert"
						style="font-size: 20px; background: none;">&#9673;&nbsp;</span>
					<span>SOS Alerts</span>
				</span>
				<span class="flex items-center mx-2 fontMediumBold"><span class="driver-alert-offline-warning"
						style="font-size: 20px; background: none;">&#9673;&nbsp;</span>
					<span>Driver Cancelled Booking</span>
				</span>

				<!-- <div class="border-l-4 p-2 m-2 customBoxShadow rounded-md reject-by-driver ng-star-inserted">
					<span class="justify-between fontMediumBold">
						<div class="ng-star-inserted"> Rejected By Driver </div>
					</span>
				</div>
				<div class="border-l-4 p-2 m-2 customBoxShadow rounded-md reject-by-system ng-star-inserted">
					<span class="justify-between fontMediumBold">
						<div class="ng-star-inserted"> Rejected By System </div>
					</span>
				</div>
				<div class="border-l-4 p-2 m-2 customBoxShadow rounded-md sos-alert ng-star-inserted">
					<span class="justify-between fontMediumBold">
						<div class="ng-star-inserted"> SOS Alerts </div>
					</span>
				</div> -->
			</div>
		</div>
		<div class="w-full">
			<div *ngFor="
          let data of alertData
            | paginate
              : {
                  itemsPerPage: alertLimit,
                  currentPage: alertCurrentpage,
                  totalItems: totalAlert,
                  id: 'alertList'
                }
        ">
				<a [href]="data.link" target="_blank" class="alertBookingLink"
					*ngIf="data.type == 3; else notEmergency">
					<div [hidden]="alertPanel"
						class="border-l-4 p-2 m-2 customBoxShadow rounded-md sos-alert cursor-pointer">
						<span class="float-right fontMediumBold">{{ moment(data.createdDate).format(Global.dateFormat)
							}}</span>
						<br />
						<span class="justify-between fontMediumBold">{{ data.message }}</span>
					</div>
				</a>
				<ng-template #notEmergency>
					<div [hidden]="alertPanel" class="border-l-4 p-2 m-2 customBoxShadow rounded-md" [ngClass]="{
              'reject-by-driver': data.type == 1,
              'driver-alert-offline-warning': data.type == 4,
              'reject-by-system': data.type == 2
            }">
						<span class="float-right fontMediumBold">{{ moment(data.createdDate).format(Global.dateFormat)
							}}</span>
						<br />
						<span class="justify-between fontMediumBold">{{ data.message }}</span>
					</div>
				</ng-template>
			</div>

			<div class="w-full" [hidden]="alertLoader">
				<div class="w-full px-4" *ngFor="let in of [].constructor(alertLimit); let i = index">
					<div class="row rounded simmer-bgcolor my-2">
						<div class="animate-pulse py-4">
							<div>
								<div class="col-md-12 pr-0 ml-1">
									<div class="col-md-3 pl-0">
										<div class="w-56 h-5 rounded"></div>
									</div>
									<div class="col-md-4 pr-0 pl-0">
										<div class="w-32 h-5 rounded"></div>
									</div>
									<div class="col-md-5">
										<div class="float-right">
											<div class="w-48 h-5 simmer-sub-bgcolor rounded"
												style="margin-right: 15px;"></div>
										</div>
									</div>
								</div>

								<div class="col-md-12 pt-4 ml-1">
									<div class="col-md-12 pl-0">
										<div class="h-5 simmer-sub-bgcolor rounded" style="width: 96%;"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="w-full h-full flex align-center items-center justify-center" *ngIf="alertData.length === 0">
				<div class="no-booking-alert">
					<svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="930.67538" height="521.87593"
						viewBox="0 0 930.67538 521.87593" xmlns:xlink="http://www.w3.org/1999/xlink">
						<path id="a08a2523-9d19-4e0c-87cb-6ce1a7fd2fc4-11" data-name="Path 133"
							d="M213.37258,618.97624a158.39338,158.39338,0,0,0,28.72768,33.86154c.254.23174.515.45361.77586.68127l23.73866-14.10511c-.1756-.23879-.36947-.50634-.57728-.79926-12.39459-17.25583-68.37231-132.05609-68.71437-157.47835C197.28,483.24064,177.17769,562.25219,213.37258,618.97624Z"
							transform="translate(-134.66231 -189.06204)" fill="#e6e6e6" />
						<path id="a62b1d0a-4504-46a8-81c8-bbc6043ad63f-12" data-name="Path 134"
							d="M237.51988,655.55937c.36662.16483.741.3251,1.118.478l17.80937-10.582c-.278-.16053-.60252-.3504-.97444-.56327-6.09666-3.56147-24.23716-14.2625-42.09847-25.91685-19.19136-12.52636-38.071-26.1452-41.34715-33.18782C172.70895,587.40853,193.83961,636.659,237.51988,655.55937Z"
							transform="translate(-134.66231 -189.06204)" fill="#e6e6e6" />
						<path id="fd401fbc-93ba-42a5-a9a4-5a6900171ed6-13" data-name="Path 133"
							d="M989.86254,665.2559a158.39406,158.39406,0,0,1-7.4,43.785c-.1.329-.211.653-.319.982h-27.613c.029-.295.059-.624.088-.982,1.841-21.166-8.677-148.453-21.369-170.483C934.36152,540.3449,992.00352,598.00187,989.86254,665.2559Z"
							transform="translate(-134.66231 -189.06204)" fill="#e6e6e6" />
						<path id="eaa08601-a63b-4214-86e2-4a8f8e2c7e12-14" data-name="Path 134"
							d="M987.79049,709.04088c-.231.329-.471.658-.717.982h-20.716c.157-.28.339-.609.55-.982,3.422-6.176,13.551-24.642,22.953-43.785,10.1-20.572,19.374-41.924,18.593-49.652C1008.69548,617.34587,1015.68746,670.4799,987.79049,709.04088Z"
							transform="translate(-134.66231 -189.06204)" fill="#e6e6e6" />
						<path
							d="M582.73018,709.255H263.402a28.57994,28.57994,0,0,1-28.54766-28.54766V217.6097A28.57994,28.57994,0,0,1,263.402,189.062H582.73018a28.57994,28.57994,0,0,1,28.54766,28.54766V680.70738A28.57994,28.57994,0,0,1,582.73018,709.255Z"
							transform="translate(-134.66231 -189.06204)" fill="#cacaca" />
						<path
							d="M583.29225,691.041H262.83992a8.8102,8.8102,0,0,1-8.80013-8.80013V216.07621a8.8102,8.8102,0,0,1,8.80013-8.80013H583.29225a8.81019,8.81019,0,0,1,8.80013,8.80013V682.24087A8.81019,8.81019,0,0,1,583.29225,691.041Z"
							transform="translate(-134.66231 -189.06204)" fill="#fff" />
						<circle cx="287.18951" cy="8.74274" r="2.42854" fill="#fff" />
						<path
							d="M1064.147,710.938H135.853a1.19068,1.19068,0,1,1,0-2.38136h928.294a1.19068,1.19068,0,1,1,0,2.38136Z"
							transform="translate(-134.66231 -189.06204)" fill="#cacaca" />
						<circle cx="288.40115" cy="355.3465" r="31" fill="#e4e4e4" />
						<path
							d="M442.47166,484.90854H403.65538L380.22154,370.27817a43.05942,43.05942,0,1,1,85.69153-.05078Z"
							transform="translate(-134.66231 -189.06204)" fill="#e4e4e4" />
						<path
							d="M575.5801,330.82838H717.454a30.15959,30.15959,0,0,1,29.42119,23.52657l71.68609,317.96779a24.3755,24.3755,0,0,1-22.57916,29.70689L650.1348,709.21552a32.20254,32.20254,0,0,1-33.27305-26.43158L576.197,457.97716l-7.692-42.52344-11.35095-62.75164a18.56906,18.56906,0,0,1,18.426-21.8737Z"
							transform="translate(-134.66231 -189.06204)" fill="#3f3d56" />
						<path
							d="M698.77657,337.68294h16.561a20.84257,20.84257,0,0,1,20.36353,16.39964l68.80881,315.37356a12.91479,12.91479,0,0,1-11.77337,15.64015l-145.37556,9.52782A16.72593,16.72593,0,0,1,629.80607,680.899l-58.715-325.96934a14.64977,14.64977,0,0,1,14.41777-17.24675h15.71067a6.91389,6.91389,0,0,1,6.67617,5.11645h0a6.10376,6.10376,0,0,0,5.89388,4.51694h74.58408a10.43362,10.43362,0,0,0,10.40289-9.6334Z"
							transform="translate(-134.66231 -189.06204)" fill="#fff" />
						<path
							d="M772.14887,438.77577a10.52534,10.52534,0,0,1,1.31643,1.00712l48.05635-12.25059L824.8,415.93891l18.14315,2.66086L839.134,439.6876a8,8,0,0,1-7.03474,6.53394L775.988,452.13061a10.49709,10.49709,0,1,1-3.83908-13.35484Z"
							transform="translate(-134.66231 -189.06204)" fill="#ffb6b6" />
						<circle cx="603.19202" cy="262.1948" r="31" [attr.fill]="Global.first_color" />
						<path
							d="M757.26253,391.75684H718.44625L695.01241,277.12647a43.05942,43.05942,0,1,1,85.69153-.05078Z"
							transform="translate(-134.66231 -189.06204)" [attr.fill]="Global.first_color" />
						<polygon
							points="711.524 240.061 699.144 250.741 681.824 265.671 674.174 226.531 673.374 222.461 672.744 219.231 690.294 188.661 704.924 189.501 705.924 189.561 705.944 189.781 706.174 191.831 709.174 218.811 709.244 219.461 711.524 240.061"
							fill="#3f3d56" />
						<polygon
							points="630.606 507.736 618.346 507.735 612.513 460.447 630.608 460.448 630.606 507.736"
							fill="#ffb6b6" />
						<path
							d="M768.39448,708.6817l-39.53076-.00146v-.5A15.38731,15.38731,0,0,1,744.2502,692.794h.001l24.144.001Z"
							transform="translate(-134.66231 -189.06204)" fill="#2f2e41" />
						<polygon
							points="758.606 508.736 746.346 508.735 740.513 461.447 758.608 461.448 758.606 508.736"
							fill="#ffb6b6" />
						<path
							d="M896.39448,709.6817l-39.53076-.00146v-.5A15.38731,15.38731,0,0,1,872.2502,693.794h.001l24.144.001Z"
							transform="translate(-134.66231 -189.06204)" fill="#2f2e41" />
						<path
							d="M903.06648,676.9734l-33,5-48.00977-162.48-50.99023,163.48-37-5,48-190v-15l6.5-22.5-.68018-6.09-4.73-42.59-2.04981-18.47-.13285-1.19313a4.05551,4.05551,0,0,1,3.19291-4.41685h0l15.39991-3.24H824.7364l14.8501,4.09,1.02.28.46.13-.23,1.92-3.48,29.18-.18994,1.6-3.3601,28.13-.74,6.17c29,21,30,42,30,42Z"
							transform="translate(-134.66231 -189.06204)" fill="#2f2e41" />
						<circle cx="674.44747" cy="148.19876" r="24.56103" fill="#ffb6b6" />
						<rect x="786.56648" y="443.4734" width="49" height="7"
							transform="matrix(0.99156, -0.12967, 0.12967, 0.99156, -185.77459, -80.11503)"
							fill="#3f3d56" />
						<path
							d="M721.14887,448.77577a10.52534,10.52534,0,0,1,1.31643,1.00712l48.05635-12.25059L773.8,425.93891l18.14315,2.66086L788.134,449.6876a8,8,0,0,1-7.03474,6.53394L724.988,462.13061a10.49709,10.49709,0,1,1-3.83908-13.35484Z"
							transform="translate(-134.66231 -189.06204)" fill="#ffb6b6" />
						<path d="M795.06648,383.9734l-8.5-6.5c-2.29817-.87172-5.44731,1.605-6,4l-12,49,25,18,3.5-5.5Z"
							transform="translate(-134.66231 -189.06204)" fill="#3f3d56" />
						<g opacity="0.2">
							<path
								d="M843.8365,407.87336a67.67557,67.67557,0,0,1-6.48,2.2c-.69995.21-1.41992.41-2.12988.6a66.019,66.019,0,0,1-14.34033,2.32,45.1399,45.1399,0,0,1-6.25977-.17,35.24271,35.24271,0,0,1-6.58984-1.3c-.07031-.02-.15039-.04-.22021-.06a28.90621,28.90621,0,0,1-11.9502-6.79,23.55972,23.55972,0,0,1-2.10986-2.35,21.23879,21.23879,0,0,1-4.56006-16.53,14.15326,14.15326,0,0,1,.34033-1.57,25.10719,25.10719,0,0,1,1.48975-4.1c.27-.62.57031-1.23.89013-1.82995l11.29981-3.82h21.52l14.8501,4.09,1,.06.02.22.46.13-.23,1.92Z"
								transform="translate(-134.66231 -189.06204)" />
						</g>
						<path
							d="M790.2894,316.92681c-4.59329-.563-9.634-.16485-13.21411,2.76738s-4.73558,9.02781-1.34592,12.17829c3.00083,2.7891,7.69329,2.13322,11.71262,2.92632a15.85418,15.85418,0,0,1,12.38363,15.15657c-.05982,10.17413-9.82411,17.87894-11.578,27.90093-1.197,6.83982,1.68772,14.03819,6.675,18.86966s11.84323,7.44255,18.7509,8.1498,13.8926-.373,20.60575-2.14766c6.74127-1.78215,13.3778-4.31055,19.03424-8.388s10.2931-9.82559,12.10928-16.55777.51227-14.45089-4.16774-19.61986c-5.35905-5.919-14.27436-7.95446-18.90989-14.45565-5.19639-7.28778-3.27352-17.66625-7.4792-25.56731-2.80792-5.27513-8.12457-8.87314-13.79191-10.76857s-11.719-2.26953-17.69212-2.4531c-3.13956-.09649-6.37527-.12863-9.30035,1.016s-5.49633,3.75316-5.648,6.89055S790.2894,316.92681,790.2894,316.92681Z"
							transform="translate(-134.66231 -189.06204)" fill="#2f2e41" />
					</svg>
					<div class="">
						<p class="fontMediumBold text-center text-9xl m-0 mt-4" [style.color]="Global.first_color">
							Not found alert data !
						</p>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-container class="app-modal-footer">
		<!-- class="w-full sticky bottom-0 z-40 pt-2"   -->
		<div class="flex justify-between" *ngIf="totalAlert > 0">
			<div class="justify-start">
				<div>
					<pagination-controls (pageChange)="alert($event)" id="alertList" previousLabel="" nextLabel="">
					</pagination-controls>
				</div>
			</div>
			<div class="justify-end flex items-center">
				<div class="text-center w-auto mr-4 pt-1 fontMediumBold">
					Total Alerts: {{totalAlert}}
				</div>
				<select (change)="alertLimitSet($event)" [(ngModel)]="alertLimit"
					class="h-full rounded-full dropDown border-gray-400 py-2 px-4 pr-8 leading-tight focus:bg-white focus:border-gray-500">
					<option>10</option>
					<option>20</option>
					<option>30</option>
					<option>40</option>
					<option>50</option>
					<option>100</option>
				</select>
			</div>
		</div>

	</ng-container>
</app-modal>
<!-- Modal Alert End -->

<ng-container *ngIf="Global.openCustomersPopup == 'header'">
	<app-customers></app-customers>
</ng-container>
<app-close-job></app-close-job>
<app-search-job></app-search-job>
<app-sticky-note></app-sticky-note>
<app-chat></app-chat>
<app-driver-alert></app-driver-alert>
<app-driver-break-alert></app-driver-break-alert>